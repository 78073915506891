.contact {
  position: relative;
}

.contact__email {
  font-size: var(--font-xxs);
}

/* From tablet landscape */
@media screen and (min-width: 400px) {
  .contact__email {
    font-size: var(--font-xs);
  }
}

/* From mobile landscape */
@media screen and (min-width: 640px) {
  .contact__email {
    font-size: var(--font-m);
  }
}

.contact__paragraph:not(:first-of-type) {
  margin-top: 0;
}

.contact__link {
  text-decoration: underline;
  color: var(--color-white);

  transition: color var(--anim-s);
}

/* From tablet landscape */
@media screen and (min-width: 1024px) {
  .contact__link:hover {
    color: var(--color-black);
  }
}
