.about {
  position: relative;
}

.about .box-link__right {
  justify-content: space-between; /* Desktop Margot 1st review */
}

.about .box-link__right p {
  margin: 0; /* Desktop Margot 1st review */
}
