/*
* Paragraph
*/

.project-content__paragraph {
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 0;

  font-size: var(--font-s2);
  letter-spacing: var(--letter-spacing-s);
}

.project-content__paragraph span {
  display: block;
}

/*
* Link
*/

.project-content__link {
  display: block;
  margin-top: 1.3rem;

  text-decoration: underline;
  text-transform: uppercase;
  font-size: var(--font-s);
  color: #006099;
}

/*
* Images and videos
*/

.project-content__media-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.project-content__media {
  width: 100%;
  margin-top: 4.6rem;

  outline: none;
}

/* From large tablet */
@media screen and (min-width: 850px) {
  .project-content__media--long {
    width: auto;
    max-height: 90vh;
  }
}

/* From desktop */
@media screen and (min-width: 1100px) {
  .project-content__media {
    margin-top: 12.6rem;
    margin-bottom: 12.6rem;
  }
  .project-content__media--long {
    width: 100%;
    max-height: none;
  }
}

/* From large desktop */
@media screen and (min-width: 1280px) {
  .project-content__media--long {
    width: auto;
    max-height: 90vh;
  }
}
