*,
*:before,
*:after {
  /* Add border and padding inside all elements */
  box-sizing: border-box;
}

html {
  height: 100%;
  /* 1rem = 10px -> 10px/16px = 62.5% */
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body {
  /* margin: 0; */
  /* Temporary fix for white space on the right... 
  * Overflow hidden is breaking the scrolling listener event
  */
  margin: 0.4rem;
  padding: 0;
  height: 100%;

  font-family: "Frutiger45", "Arial", sans-serif;
  font-size: 15px;
}

/* From tablet landscape */
@media screen and (min-width: 1024px) {
  body {
    margin: 0;
  }
}

@font-face {
  font-family: "Frutiger45";
  font-display: fallback;
  src: url(/fonts/Frutiger-LT-45-Light.woff) format("woff"),
    url(/fonts/Frutiger-LT-45-Light.ttf) format("ttf");
}

@font-face {
  font-family: "Frutiger95";
  font-display: fallback;
  src: url(/fonts/Frutiger-LT-Std-95-Ultra-Black.woff) format("woff"),
    url(/fonts/Frutiger-LT-45-Light.ttf) format("ttf");
}

:root {
  /* Animation */
  --anim-xl: 3s;
  --anim-l: 2s;
  --anim-m: 1.5s;
  --anim-s: 1s;
  --anim-xs: 0.5s;

  /* Breakpoints (need preprocessor) */
  --desktop-l: 1400px;
  --desktop-m: 1280px;
  --desktop-s: 1100px;
  --tablet-landscape: 1024px;
  --tablet-portrait-l: 850px;
  --tablet-portrait-m: 800px;
  --tablet-portrait: 720px;
  --phone-landscape: 640px;
  --phone-portrait-l: 500px;
  --phone-portrait-m: 425px;
  --phone-portrait-s: 400px;
  --phone-portrait-xs: 320px;

  /* Colors */
  --color-black: #000;
  --color-grey-xl: #777;
  --color-grey-l: #aaa;
  --color-grey-m: #bbb;
  --color-grey-s: #ccc;
  --color-white: #fff;

  /* Font */
  --font-xxl: 4.5rem;
  --font-xl2: 2.8rem;
  --font-xl: 2.5rem;
  --font-l2: 2.2rem;
  --font-l: 2rem;
  --font-m3: 1.7rem;
  --font-m2: 1.6rem;
  --font-m: 1.5rem;
  --font-s3: 1.4rem;
  --font-s2: 1.3rem;
  --font-s: 1.2rem;
  --font-xs: 1.1rem;
  --font-xxs: 0.9rem;
  --weight-m: 600;
  --weight-s: 500;
  --letter-spacing-l: 4.7px;
  --letter-spacing-m: 2px;
  --letter-spacing-s: 1px;
}
