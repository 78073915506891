.language-switch {
  position: absolute;
  top: 1.5rem;
  right: 1rem;
  z-index: 100;

  color: var(--color-grey-m);
}

/* From tablet landscape */
@media screen and (min-width: 640px) {
  .language-switch {
    top: 5rem;
    right: 5rem;
  }
}

.language-switch__btn {
  position: relative;

  margin-right: 0 !important;
  background-color: transparent;
  border: none;
  outline: none;

  font-size: var(--font-s2);
  font-family: inherit;
  color: var(--color-grey-m);
  cursor: pointer;
  transition: color var(--anim-s);
}

/* From tablet landscape */
@media screen and (min-width: 640px) {
  .language-switch__btn {
    font-size: var(--font-m2);
  }
}

/* From tablet landscape */
@media screen and (min-width: 1024px) {
  .language-switch__btn:hover {
    color: var(--color-black);
  }
}
