/* From tablet landscape */
.project-left-column {
  position: relative;
  z-index: 10;

  width: 100%;
  padding: 10vw;
  padding-top: 15vw;
}

/* From tablet landscape */
@media screen and (min-width: 640px) {
  .project-left-column {
    padding-top: 10vw;
  }
}

/* From tablet landscape */
@media screen and (min-width: 1100px) {
  .project-left-column {
    width: 30%;
    min-width: 42rem;
    padding: 0;

    border-right: 1px solid var(--color-grey-s);
  }
}

.project-left-column .project__logo,
.project-left-column .project-left-column__description {
  display: none;
}

/* From tablet landscape */
@media screen and (min-width: 1100px) {
  .project-left-column .project__logo,
  .project-left-column .project-left-column__description {
    display: flex;
  }
}

.project-left-column .goback-btn,
.project-left-column .project-left-column__title-wrapper,
.project-left-column .project__courtesy,
.project-left-column .project__img {
  display: flex;
}

/* From tablet landscape */
@media screen and (min-width: 1100px) {
  .project-left-column .goback-btn,
  .project-left-column .project-left-column__title-wrapper,
  .project-left-column .project__courtesy,
  .project-left-column .project__img {
    display: none;
  }
}

/*
* From desktop
* Because we use a min-width on the left column
* We need to fix the padding around the text
*/
@media screen and (min-width: 1100px) {
  .project-left-column .project-left-column__content {
    padding-left: 7.6rem;
    padding-right: 7.6rem;
  }
}

/*
* From large desktop
* The min-width on the left column is reached under 1400px
* Above, we can apply the same behaviour as the right column
*/
@media screen and (min-width: 1400px) {
  .project-left-column .project-left-column__content {
    padding-left: 6%;
    padding-right: 6%;
  }
}

/* From tablet landscape */
@media screen and (min-width: 1100px) {
  .project-left-column__content {
    position: fixed;

    width: 30%;
    min-width: 42rem;
    height: 100vh;
    padding: 12.6rem 6% 7rem;
  }
}

/*
* General content
*/

/* From tablet landscape */
@media screen and (min-width: 1100px) {
  .project-left-column .project-content {
    max-height: calc(
      100vh - 12.6rem - 6rem - 1.9rem - 10rem - 3.2rem - 1.9rem - 10rem - 3.2rem -
        5rem
    );
    overflow-y: auto; /* Scroll when necessary */
  }

  /* Special style for scrollbar */
  div::-webkit-scrollbar {
    width: 5px;
  }
  div::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  div::-webkit-scrollbar-thumb {
    background: #ccc;
  }
}

/*
* Logo
*/

.project__logo {
  align-items: center;
  justify-content: center;
  /* project__title margin-bottom + project-subtitle height + project-subtitle margin-bottom */
  margin-bottom: calc(0.5rem + 1.4rem + 10rem);

  font-family: "Frutiger95", "Helvetica Neue", Helvetica, sans-serif;
  font-size: var(--font-xl);
  text-decoration: none;
  text-transform: uppercase;
  color: var(--color-black);
}

/*
* Description
*/

.project-left-column__description {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* project__title margin-bottom + project-subtitle height + project-subtitle margin-bottom */
  margin-bottom: calc(0.5rem + 1.4rem + 10rem);

  font-size: var(--font-s2);
  letter-spacing: var(--letter-spacing-s);
}

/*
* Title
*/

.project-left-column__title-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 6rem;
}

/* From tablet landscape */
@media screen and (min-width: 1100px) {
  .project-left-column__title-wrapper {
    margin-bottom: 10rem;
  }
}

/*
* Info
*/

.project-left-column__info {
  margin: 0;

  font-size: var(--font-s2);
  font-weight: var(--weight-s);
  letter-spacing: var(--letter-spacing-s);
}

.project-left-column__info span {
  display: block;
}

/*
* Loader
*/

.project-left-column .loader {
  padding-top: 4rem;
}
