.project {
  display: flex;
  flex-direction: column;
}

/* From tablet landscape */
@media screen and (min-width: 1100px) {
  .project {
    flex-direction: row;
  }
}

/*
* Language switch
*/

.project .language-switch {
  top: 1.9rem;
  right: 1.4rem;
}

/* From tablet landscape */
@media screen and (min-width: 640px) {
  .project .language-switch {
    top: 5rem;
    right: 5rem;
  }
}

/*
* Not found
*/

.project__not-found {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;

  font-size: var(--font-l);
  animation: shownotfound 2s;
}

@keyframes shownotfound {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.project__not-found-link {
  margin-left: 0.5rem;

  border-bottom: 1px solid var(--color-black);
  text-decoration: none;
  color: var(--color-black);

  transition: transform var(--anim-s);
}

/* From tablet landscape */
@media screen and (min-width: 1100px) {
  .project__not-found-link:hover {
    transform: scale(1.1);
  }
}

/*
* Both columns
*/

.project__block {
  position: relative;

  text-align: center;
}

/*
* Mosaic
*/

.project .projects-mosaic {
  width: 22rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 6rem;
  padding: 0;
}

/* From desktop */
@media screen and (min-width: 1100px) {
  .project .projects-mosaic {
    animation: showmosaic 3s;
  }
}

/*
* Trick to hide the flashy effect of the mosaic
* When the page is being reload after a project switch
*/
@keyframes showmosaic {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.project .projects-mosaic .projects-mosaic__box {
  height: 3rem;
  padding: 0;
}

/* From small mobile */
@media screen and (min-width: 400px) {
  .project .projects-mosaic {
    width: 30rem;
  }
  .project .projects-mosaic .projects-mosaic__box {
    height: 4rem;
  }
}

/* From medium mobile */
@media screen and (min-width: 425px) {
  .project .projects-mosaic {
    width: 33rem;
  }
}

/* From small desktop */
@media screen and (min-width: 1100px) {
  .project .projects-mosaic {
    position: absolute;
    top: calc(33rem / 2 - 0.5rem);

    /* 32.8 instead of 33 because border of 1px */
    right: calc(-32.8rem / 2);
    transform: rotate(90deg) translate(0vh, 0%);
    z-index: 100;

    width: 33rem;
    margin-bottom: 0;
  }

  .project .projects-mosaic .projects-mosaic__box {
    height: 2rem;
  }
}

/* From large desktop */
@media screen and (min-width: 1400px) {
  .project .projects-mosaic {
    right: calc(-32.5rem / 2);
  }
}

/* From small desktop */
@media screen and (min-width: 1100px) {
  /* Safari behaves differently... */
  _::-webkit-full-page-media,
  _:future,
  :root .safari-only {
    top: calc(34rem / 2 - 0.5rem);
  }
}

/*
* Title (in both columns)
*/

.project__title {
  margin: 0 0 0.5rem;
  margin-bottom: 0.5rem;

  font-family: "Frutiger95", "Helvetica Neue", Helvetica, sans-serif;
  font-size: var(--font-xl);
  text-decoration: none;
  text-transform: uppercase;
}
