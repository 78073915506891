.home {
  position: relative;
  width: 100%;
}

@media screen and (max-width: 1024px) {
  .home .projects-mosaic {
    position: relative;
    transition: transform var(--anim-xs);
  }
}

@media screen and (min-width: 1024px) {
  .home .projects-mosaic {
    /* When we arrive on the home page */
    animation: showhomeblock var(--anim-m);
    /* Transform appears when we switch the home block */
    transition: transform var(--anim-s);
    transition-timing-function: ease-in-out;
  }
}

/* When we leave the home block */
@keyframes hidehomeblock {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

/* When we arrive on the home page */
@keyframes showhomeblock {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.home_swipe-info {
  position: absolute;
  top: calc(21rem / 2);
  left: calc(7rem + 1rem);
  transform: translate(-50%, -50%) rotate(-90deg);

  margin: 0;

  font-size: var(--font-xs);
  letter-spacing: var(--letter-spacing-s);
  color: var(--color-grey-m);
}

@keyframes showswipeinfo {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(3rem);
  }
  100% {
    transform: translateX(0%);
  }
}

@media screen and (min-width: 640px) {
  .home_swipe-info {
    top: calc(21rem + 21rem / 2);
  }
}

@media screen and (min-width: 1024px) {
  .home_swipe-info {
    display: none;
  }
}

.home_goup-btn {
  position: fixed;
  bottom: calc(8rem / 2);
  right: calc(7.6rem / 2);
  transform: translate(50%, 50%) rotate(-90deg);
  z-index: 1;

  display: none;
  padding: 0;

  background-color: transparent;
  text-decoration: none;
  border: none;
  outline: none;
  cursor: pointer;

  animation: showgoupbtn 2s;
}

@keyframes showgoupbtn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.home_goup-btn__icon {
  width: 3rem;
  height: 3rem;
}

.home_goup-btn__icon-line {
  fill: none;
  stroke: #c9c9c9;
  stroke-width: 2;
  stroke-miterlimit: 10;

  transition: stroke var(--anim-m);
}

.home .switch-btn {
  /* 12rem/2 (padding-bottom under mosaic) + 21rem (height of one mosaic rectangle */
  top: calc(50% - 6rem - 21rem);
}

@media screen and (min-width: 1400px) {
  .home .switch-btn {
    /* 12rem/2 (padding-bottom under mosaic) */
    top: calc(50% - 6rem);
  }
}
