.project-right-column {
  display: none;
}

/* From desktop */
@media screen and (min-width: 1100px) {
  .project-right-column {
    display: block;
    width: 70%;
  }
}

.project-right-column .project-subtitle {
  margin-bottom: 6rem;
}

/* From tablet landscape */
@media screen and (min-width: 1100px) {
  .project-right-column .project-subtitle {
    margin-bottom: 10rem;
  }
}

.project-right-column .project-content__media:first-of-type {
  margin-top: 0;
}

.project-right-column .project-content__media:last-of-type {
  margin-bottom: 9rem;
}

.project-right-column .loader {
  padding-bottom: 4rem;
}

/*
* From desktop
* The padding is only 15% to give medias more space
* Since only the right-column's width reduces under 1400px
*/
@media screen and (min-width: 1100px) {
  .project-right-column__white-block {
    display: flex;
    flex-direction: column;
    padding: 12.6rem 15% calc(12.6rem - 9rem);
  }
}

/*
  * From desktop
  * Above 1400px, both column's width will grow
  * So we can increase the margins around medias
  */
@media screen and (min-width: 1400px) {
  .project-right-column__white-block {
    padding-left: 20%;
    padding-right: 20%;
  }
}
