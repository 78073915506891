.instagram {
  position: relative;
}

.instagram__link {
  text-decoration: underline;
  color: var(--color-white);

  transition: color var(--anim-s);
}

.instagram__link:hover {
  color: var(--color-black);
}

.instagram__link:first-child {
  margin-bottom: 1rem;
}

/* From tablet landscape */
@media screen and (min-width: 1024px) {
  .instagram__link:first-child {
    margin-bottom: 2rem;
  }
}
