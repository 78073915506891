.goback-btn {
  position: absolute;
  top: calc(15vw / 2);
  left: calc(100vw / 2);
  transform: translate(-50%, -50%);

  padding-right: 1.4rem;

  background-color: transparent;
  text-decoration: none;
  border: none;
  outline: none;
  cursor: pointer;
}

/* Safari behaves differently... */
_::-webkit-full-page-media,
_:future,
:root .goback-btn--safari {
  padding-left: 0.2rem;
}

/* From medium mobile */
@media screen and (min-width: 640px) {
  .goback-btn {
    top: calc(10vw / 2);
  }
}

/* From tablet landscape */
@media screen and (min-width: 1024px) {
  .goback-btn {
    padding-right: 0.6rem;
  }
}

.goback-btn__icon {
  width: 2.5rem;
  height: 2.5rem;
  transform: rotate(270deg);
  transition: transform var(--anim-xs);
}

/* From tablet landscape */
@media screen and (min-width: 1100px) {
  .goback-btn:hover .goback-btn__icon {
    transform: rotate(270deg) scale(1.2);
  }
}

.goback-btn__icon-line {
  fill: none;
  stroke: var(--color-grey-l);
  stroke-width: 2;
  stroke-miterlimit: 10;
}
