.projects-mosaic {
  display: flex;
  flex-wrap: wrap;
  padding: 0 7rem 7rem 7rem;
}

/* From tablet landscape */
@media screen and (min-width: 1024px) {
  .projects-mosaic {
    padding: 0 12rem 12rem 12rem;
  }
}

.projects-mosaic__box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10%;
  height: 21rem;
  padding: 0;

  color: var(--color-white);
  text-align: center;
  text-decoration: none;

  transition: background-color var(--anim-m), color var(--anim-m);
}

.projects-mosaic__box--responsive {
  width: 100%;
}

/* From mobile landscape */
@media screen and (min-width: 425px) {
  .projects-mosaic__box--responsive {
    width: 50%;
  }
}

/* From tablet portrait */
@media screen and (min-width: 640px) {
  .projects-mosaic__box--responsive {
    width: calc(100% / 3);
  }
}

/* From large tablet */
@media screen and (min-width: 800px) {
  .projects-mosaic__box--responsive {
    width: 25%;
  }
}

/* From desktop */
@media screen and (min-width: 1024px) {
  .projects-mosaic__box--responsive {
    width: calc(100% / 5);
  }
}

/* From large desktop */
@media screen and (min-width: 1500px) {
  .projects-mosaic__box--responsive {
    width: 10%;
  }
}

.projects-mosaic__box[title] {
  padding: 3rem;

  font-family: "Frutiger95", "Helvetica Neue", Helvetica, sans-serif;
  font-size: var(--font-xs);
  letter-spacing: var(--letter-spacing-s);
  text-transform: uppercase;
  cursor: pointer;
}

/* From desktop */
@media screen and (min-width: 1024px) {
  .projects-mosaic__box[title]:hover {
    background-color: var(--color-white) !important;
    color: var(--color-black);
  }
}

.projects-mosaic__box-selection {
  height: 0.8rem;
  width: 0.8rem;
  border-radius: 50%;
  background-color: var(--color-white);
  transition: background-color var(--anim-m);
}

/* From desktop */
@media screen and (min-width: 1024px) {
  .projects-mosaic__box:hover .projects-mosaic__box-selection {
    background-color: var(--color-black);
  }
}
