.box-link__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 20%;

  color: var(--color-white);
}

/* Until tablet landscape (swipe effect) */
@media screen and (max-width: 1024px) {
  .box-link__wrapper {
    transition: transform var(--anim-xs);
  }
}

/* From tablet landscape */
@media screen and (min-width: 1024px) {
  .box-link__wrapper {
    flex-direction: row;
    padding-bottom: 0;

    /* When we arrive on the box link */
    animation: showboxlink var(--anim-m);
    /* Transform appears when we switch the box link */
    transition: transform var(--anim-s);
    transition-timing-function: ease-in-out;
  }
}

/* When we leave the box link */
@keyframes hideboxlink {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

/* When we arrive on the box link */
@keyframes showboxlink {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.box-link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20rem;
  height: 32rem;
  padding: 2rem;

  font-size: var(--font-xs);
}

.box-link__left {
  height: 20rem;
}

@media screen and (min-width: 400px) {
  .box-link {
    width: 27rem;
    height: 27rem;
  }
}

@media screen and (min-width: 500px) {
  .box-link {
    width: 33rem;
    height: 33rem;
    padding: 4rem;
  }
}

/* From mobile landscape */
@media screen and (min-width: 640px) {
  .box-link {
    width: 38rem;
    height: 38rem;

    font-size: var(--font-m);
  }
}

/* From tablet landscape */
@media screen and (min-width: 1024px) {
  .box-link {
    width: 42rem;
    height: 42rem;
    padding: 7rem;
  }
}

.box-link__left {
  font-family: "Frutiger95", "Helvetica Neue", Helvetica, sans-serif;
  letter-spacing: var(--letter-spacing-s);
  text-transform: uppercase;
  font-size: var(--font-l);
}

/* From tablet landscape */
@media screen and (min-width: 1024px) {
  .box-link__left {
    font-size: var(--font-xl);
  }
}

.box-link__right {
  flex-direction: column;

  text-align: center;
  letter-spacing: var(--letter-spacing-s);
}

.box-link__link {
  font-family: "Frutiger95", "Helvetica Neue", Helvetica, sans-serif;
  letter-spacing: var(--letter-spacing-s);
  text-decoration: none;
  text-transform: uppercase;
  color: var(--color-white);

  transition: color var(--anim-m);
}

/* From tablet landscape */
@media screen and (min-width: 1024px) {
  .box-link__link:hover {
    color: var(--color-black);
  }
}
