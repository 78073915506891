.project-courtesy {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 4.6rem 5% 0;

  font-size: var(--font-xs);
  letter-spacing: var(--letter-spacing-s);
  text-align: center;
}

/* From tablet landscape */
@media screen and (min-width: 1100px) {
  .project-courtesy {
    margin-bottom: 10rem;
  }
}
