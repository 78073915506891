.loader__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.loader {
  position: relative;

  display: inline-block;
  width: 8rem;
  height: 8rem;
}

.loader div {
  position: absolute;
  top: 3.3rem;

  width: 1.3rem;
  height: 1.3rem;

  border-radius: 50%;
  background-color: #000;
  opacity: 0.1;

  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.loader div:nth-child(1) {
  left: 0.8rem;
  animation: loader1 var(--anim-xs) infinite;
}

.loader div:nth-child(2) {
  left: 0.8rem;
  animation: loader2 var(--anim-xs) infinite;
}

.loader div:nth-child(3) {
  left: 3.2rem;
  animation: loader2 var(--anim-xs) infinite;
}

.loader div:nth-child(4) {
  left: 5.6rem;
  animation: loader3 var(--anim-xs) infinite;
}

@keyframes loader1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes loader2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(2.4rem, 0);
  }
}

@keyframes loader3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
