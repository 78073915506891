.switch-btn {
  position: fixed;
  top: 50%;
  z-index: 1;

  display: none;
  padding: 0;

  background-color: transparent;
  text-decoration: none;
  border: none;
  outline: none;
  cursor: pointer;
}

/* From tablet landscape */
@media screen and (min-width: 1024px) {
  .switch-btn {
    position: absolute;
    display: flex;
  }
}

.switch-btn--left {
  left: calc(12rem / 2);
  transform: translate(-50%, -50%);
}

/* From tablet landscape */
@media screen and (min-width: 1280px) {
  .switch-btn--left {
    left: calc(9rem / 2);
    transform: translate(-50%, -50%);
  }
}

.switch-btn--right {
  right: calc(12rem / 2);
  transform: translate(50%, -50%);
}

/* From tablet landscape */
@media screen and (min-width: 1280px) {
  .switch-btn--right {
    right: calc(9rem / 2);
    transform: translate(50%, -50%);
  }
}

.switch-btn__icon {
  width: 3rem;
  height: 3rem;

  transition: transform var(--anim-xs);
}

@media screen and (min-width: 500px) {
  .switch-btn__icon {
    width: 3.5rem;
    height: 3.5rem;
  }
}

/* From tablet landscape */
@media screen and (min-width: 1280px) {
  .switch-btn__icon {
    width: 3rem;
    height: 3rem;
  }
}

.switch-btn__icon--left {
  transform: rotate(180deg);
}

.switch-btn:hover .switch-btn__icon--left {
  transform: rotate(180deg) scale(1.2);
}

.switch-btn:hover .switch-btn__icon--right {
  transform: scale(1.2);
}

.switch-btn__icon-line {
  fill: none;
  stroke: var(--color-grey-m);
  stroke-width: 2;
  stroke-miterlimit: 10;

  transition: stroke var(--anim-m);
}

.switch-btn__icon:hover .switch-btn__icon-line {
  stroke: var(--color-black);
}
