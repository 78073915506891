.resume {
  /* padding-left: 10%; Removed for resume img */
  /* padding-right: 10%; Removed for resume img */
  /* padding-bottom: 5rem; Removed for resume img */
  padding: 0 12% 10%;
}

@media screen and (min-width: 1024px) {
  .resume {
    padding: 0 20% 10%;
  }
}

@media screen and (min-width: 1280px) {
  .resume {
    padding: 0 25% 10%;
  }
}

/* @media screen and (min-width: 640px) {
  .resume {
    padding-left: 25%; Removed for resume img
    padding-right: 25%; Removed for resume img
  }
}
@media screen and (min-width: 1024px) {
  .resume {
    padding-left: 0; Removed for resume img
    padding-right: 0; Removed for resume img
  }
} */

.resume__img {
  width: 100%;
}

.resume__block {
  display: flex;
  letter-spacing: var(--letter-spacing-s);
  padding: 0;

  text-align: center;
}

.resume__block:not(:last-child) {
  margin-bottom: 3.5rem;
}

/* From tablet landscape */
@media screen and (min-width: 1024px) {
  .resume__block {
    text-align: initial;
  }
}

.resume__block--column {
  flex-direction: column;
}

.resume__block--wrap {
  flex-wrap: wrap;
}

.resume__title {
  font-family: "Frutiger95", "Helvetica Neue", Helvetica, sans-serif;
  text-transform: uppercase;
}

.resume__title--part {
  margin-bottom: 4rem;
  font-size: var(--font-m2);
}

/* From tablet landscape */
@media screen and (min-width: 1024px) {
  .resume__title--part {
    margin-bottom: 3rem;
    font-size: var(--font-xl2);
  }
}

.resume__content-inner .resume__title--part {
  margin-bottom: 2.5rem;
}

/* From tablet landscape */
@media screen and (min-width: 1024px) {
  .resume__content-inner .resume__title--part {
    margin-bottom: 3rem;
  }
}

.resume__title--right {
  text-align: center;
}

/* From tablet landscape */
@media screen and (min-width: 1024px) {
  .resume__title--right {
    margin-left: 50%;
    text-align: initial;
  }
}

.resume__content-block {
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;

  text-align: center;
}

/* From tablet landscape */
@media screen and (min-width: 1024px) {
  .resume__content-block {
    display: flex;
    flex-direction: row;
    margin-bottom: 2rem;

    text-align: initial;
  }
}

.resume__content-block--left {
  width: 100%;
  text-align: center;
}

/* From tablet landscape */
@media screen and (min-width: 1024px) {
  .resume__content-block--left {
    width: 50%;
    padding-right: 6rem;
    text-align: right;
  }
}

.resume__content-block--left--second {
  margin-bottom: 3rem;
}

.resume__content-block--right {
  width: 100%;
}

/* From tablet landscape */
@media screen and (min-width: 1024px) {
  .resume__content-block--right {
    width: 50%;
  }
}

.resume__content-block--right--second:not(:last-child) {
  margin-bottom: 3.5rem;
}

.resume__content-inner {
  display: flex;
  flex-direction: column;
}

.resume__content-inner:not(:last-child) {
  margin-bottom: 3rem;
}

/* From tablet landscape */
@media screen and (min-width: 1024px) {
  .resume__content-inner {
    margin-bottom: 2rem;
  }
}

.resume__title--content {
  margin-bottom: 1rem;
}

/* From tablet landscape */
@media screen and (min-width: 1024px) {
  .resume__title--content {
    font-size: var(--font-l);
  }
}

.resume__content-info {
  color: var(--color-grey-l);
  font-size: var(--font-s);
}

/* From tablet landscape */
@media screen and (min-width: 1024px) {
  .resume__content-info {
    font-size: var(--font-s3);
  }
}

.resume__text {
  margin: 0;

  font-size: var(--font-xs);
  line-height: 2rem;
}

/* From tablet landscape */
@media screen and (min-width: 1024px) {
  .resume__text {
    width: 50rem;

    font-size: var(--font-m);
    line-height: 2.5rem;
  }
}

.resume__text--right {
  margin-left: 0;
}

/* From tablet landscape */
@media screen and (min-width: 1024px) {
  .resume__text--right {
    margin-left: calc(100% - 50rem);
  }
}

.resume__text--bottom:not(:last-child) {
  margin-bottom: 1rem;
}
