.project-subtitle {
  margin: 0;

  font-size: var(--font-xs);
  font-weight: var(--weight-s);
  text-transform: uppercase;
  letter-spacing: var(--letter-spacing-m);
}

.project-subtitle span {
  display: block;
}

/* From tablet portrait */
@media screen and (min-width: 640px) {
  .project-subtitle span {
    display: inline;
  }
}
