.header {
  position: relative;

  padding: 5.5rem 0;
  text-align: center;
}

/* From tablet landscape */
@media screen and (min-width: 1024px) {
  .header {
    /* 10.8rem of header + 12rem of footer + 42rem of mosaic */
    padding: calc((100vh - 10.8rem - 12rem - 42rem) / 2);
  }
}

@media screen and (max-height: 750px) {
  .header {
    padding: 5rem 0;
  }
}

.header__title {
  font-family: "Frutiger95", "Helvetica Neue", Helvetica, sans-serif;
  font-size: var(--font-l2);
  text-decoration: none;
  text-transform: uppercase;
  line-height: 4.5rem;
  color: var(--color-black);
}

/* From tablet landscape */
@media screen and (min-width: 1024px) {
  .header__title {
    font-size: var(--font-xxl);
  }
}

.header__subtitle {
  display: flex;
  flex-direction: column;
  margin: 0.3rem 0 1rem 0;

  font-size: var(--font-xs);
}

/* From tablet landscape */
@media screen and (min-width: 1024px) {
  .header__subtitle {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: var(--font-m3);
  }
}

.header__subtitle-text {
  letter-spacing: var(--letter-spacing-l);
  margin-right: -0.4rem;
}

.header__subtitle-text-separator {
  display: none;

  margin: 0 1.2rem;
  font-family: "Frutiger95", "Helvetica Neue", Helvetica, sans-serif;
}

/* From tablet landscape */
@media screen and (min-width: 1024px) {
  .header__subtitle-text-separator {
    display: block;
  }
}

.header__links {
  display: flex;
  align-items: center;
  justify-content: center;
}

.header__link {
  margin-right: -0.2rem;

  font-size: var(--font-s);
  letter-spacing: var(--letter-spacing-m);
  text-decoration: none;
  color: var(--color-grey-m);

  transition: color var(--anim-m);
}

/* From tablet landscape */
@media screen and (min-width: 1024px) {
  .header__link {
    font-size: var(--font-m);
  }
}

/* From tablet landscape */
@media screen and (min-width: 1024px) {
  .header__link:hover {
    color: var(--color-black);
  }
}

.header__link-separator {
  margin: 0 0.7rem;

  font-size: var(--font-xxs);
  font-weight: var(--weight-m);
  color: var(--color-black);
}

/* From tablet landscape */
@media screen and (min-width: 1024px) {
  .header__link-separator {
    font-size: var(--font-xs);
  }
}
