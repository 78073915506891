.project-black-block {
  display: flex;
  flex-direction: column;
  padding: 10vw;

  background-color: var(--color-black);
  color: var(--color-grey-xl);
}

@media screen and (min-width: 1100px) {
  .project-black-block {
    padding: 12.6rem 20% 4rem;
  }
}
